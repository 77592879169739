import React from 'react';
export default function ContactForm() {
  return (
    <form method="post" name="contacto" netlify-honeypot="bot-field" data-netlify="true" action="/Enviado">
      <input type="hidden" name="bot-field" />
      <div className="row">
        <div className="col-6 col-12-mobilep">
          <input type="text" name="name" placeholder="Nombre" />
        </div>
        <div className="col-6 col-12-mobilep">
          <input type="email" name="email" placeholder="Email" />
        </div>
        <div className="col-12">
          <textarea name="message" placeholder="Consulta" rows="6"></textarea>
        </div>
        <div className="col-12">
          <ul className="actions special">
            <li>
              <input type="submit" value="Enviar Consulta" />
            </li>
          </ul>
        </div>
      </div>
    </form>
  );
}
