import React from 'react';
import ContactForm from './ContactForm';
import config from '../../config';
export default function Footer() {
  return (
    <div id="footer">
      <div className="container medium">
        <header id="consultas" className="major last">
          <h2>¿Tiene una consulta?</h2>
        </header>

        <p>No dude en contactarnos</p>

        <ContactForm />

        <ul className="icons">
          {config.socialLinks.map(social => {
            const { icon, name, url } = social;
            return (
              <li key={url}>
                <a href={url} className={`icon ${icon}`} target="_blank">
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          })}
        </ul>

        <ul className="copyright">
          <li>&copy; JC Y Asociados - {(new Date().getFullYear())}</li>
        </ul>
      </div>
    </div>
  );
}
