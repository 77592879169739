import React from 'react';

import Layout from '../components/Layout';
import GoogleMap from '../components/GoogleMap';

import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import picTransito from '../assets/images/transito.jpg';
import picLaboral from '../assets/images/laboral.jpg';
import picPrevision from '../assets/images/prevision.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container medium">
        <h2>
          Somos un equipo de profesionales <br /> especializados y capacitados
          para <br /> defender sus intereses
        </h2>
      </header>

      <div className="box alt container">
        <section className="feature right">
          <a href="#consultas" className="image icon">
            <img src={picPrevision} alt="" />
          </a>
          <div className="content">
            <h3>Derecho Previsional</h3>
            <p>
              Tenemos un amplio conocimiento en normativas previsionales. Si
              esta buscando jubilarse no dude en contactar sobre gestiones para
              jubilaciones, pensiones o reajustes de haberes (de ANSES) para obtener el
              resultado esperado de manera eficaz.
            </p>
          </div>
        </section>
        <section className="feature left">
          <a href="#consultas" className="image icon">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h3>Derecho Civil</h3>
            <p>
              El derecho civil es la rama del derecho privado que regula las
              principales relaciones civiles de las personas. Tradicionalmente
              es la rama del derecho que regula el estado civil de las personas,
              las relaciones familiares, la propiedad y los demás derechos
              reales, las obligaciones y contratos, y las sucesiones.
            </p>
          </div>
        </section>
        <section className="feature right">
          <a href="#consultas" className="image icon">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h3>Derecho de Familia</h3>
            <p>
              Es la parte del Derecho Civil que tiene por objeto las relaciones
              jurídicas familiares: relaciones conyugales, paterno-filiales,
              tanto en su aspecto personal como patrimonial, la tutela y las
              demás instituciones de protección de menores e incapacitados.
              Constituye el eje central la familia, el matrimonio y la
              filiación.
            </p>
          </div>
        </section>
        <section className="feature left">
          <a href="#consultas" className="image icon">
            <img src={picTransito} alt="" />
          </a>
          <div className="content">
            <h3>Accidentes de tránsito</h3>
            <p>
              Lo ayudamos a obtener su indemnización si sufrió un accidente de
              transito, ya sea un ciclista, motociclista, tercero transportado o
              conductor de un automovil.
            </p>
          </div>
        </section>

        <section className="feature right">
          <a href="#consultas" className="image icon">
            <img src={picLaboral} alt="" />
          </a>
          <div className="content">
            <h3>Accidentes de Trabajo</h3>
            <p>
              Proveemos informacion y asesoramiento sobre derechos, reclamación
              y denuncia de accidentes de trabajo.
            </p>
          </div>
        </section>

        <section className="feature left">
          <a href="#consultas" className="image icon">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h3>Derecho Comercial</h3>
            <p>
              Contratos, cobranza y ejecución de créditos, defensa al
              consumidor, protección de datos personales, amparos de salud,
              negocios inmobiliarios y cualquier otro asunto relacionado a actos
              de comercio.
            </p>
          </div>
        </section>
      </div>

      <footer className="major container medium">
        <h3>Dónde encontrarnos</h3>
        <GoogleMap></GoogleMap>
      </footer>
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
