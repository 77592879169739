import React from 'react';

export default function GoogleMap() {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3127.8366182672694!2d-60.28966518425068!3d-38.37590087965457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95926dcc6b65257f%3A0x84be986df156ed38!2sLas%20Heras%20536%2C%20Tres%20Arroyos%2C%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1568564795654!5m2!1sen!2sar"
        width="100%"
        height="400px"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
      ></iframe>
    </div>
  );
}
